import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper l7kuc1ms3b-editor_css' },
  page: { className: 'home-page l7ku9zf1c0j-editor_css' },
  logo: {
    className: 'header3-logo l7ku9pm04b-editor_css',
    children:
      'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruilulogo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item l7kuecsjmn-editor_css',
        children: {
          href: '#Content4_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>瑞露君洋酒业</p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'l7kuas5y0r-editor_css',
            },
          ],
          className: 'l7kueh27lt-editor_css',
          target: '',
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item l7kuf5zrojq-editor_css',
        children: {
          href: '#Content13_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>53°瑞露千秋</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'l7kudy1cv7k-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item2',
        className: 'header3-item l7kugn8i23-editor_css',
        children: {
          href: '#Content13_3',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>42°瑞露君蘭</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'l7kue8d5pna-editor_css',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item l7kuf9ouqt-editor_css',
        children: {
          href: '#Content13_4',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>代理商服务业务</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'l7kuea1xa6-editor_css',
            },
          ],
        },
      },
      {
        name: 'item~l7pt0t12u',
        className: 'header3-item l7kuf9ouqt-editor_css',
        children: {
          href: '#Footer1_1',
          children: [
            {
              children: (
                <span>
                  <p>联系我们</p>
                </span>
              ),
              name: 'text',
              className: 'l7kuea1xa6-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1 l7m579sfe5-editor_css' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem l7m550qcnkm-editor_css' },
        textWrapper: {
          className: 'banner1-text-wrapper l7k7xzcr4np-editor_css',
        },
        bg: { className: 'bg bg0 l7k753lsy4a-editor_css' },
        title: {
          className: 'banner1-title l7kujh5cnkm-editor_css',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner1-content l7onn77z5qm-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      <br />
                    </p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        button: {
          className: 'banner1-button l7k84ddwvlt-editor_css',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem l7m53wlopqg-editor_css' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1 l7k75uusgmc-editor_css' },
        title: {
          className: 'banner1-title l7ky58e18et-editor_css',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
        content: {
          className: 'banner1-content l7ky5i69v4q-editor_css',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner1-button l7ky5oqdsll-editor_css',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper l7onofqj1bs-editor_css',
        },
        bg: { className: 'bg bg1 l7k76a0ko3-editor_css' },
        title: { className: 'banner1-title', children: '' },
        content: {
          className: 'banner1-content l7onoch9azu-editor_css',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner1-button l7ky6ipyk1-editor_css',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
    ],
    type: ['across'],
  },
};
export const Content40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content4-wrapper l7oinn39ytm-editor_css',
  },
  page: { className: 'home-page content4 l7l02u3cwsi-editor_css' },
  OverPack: { playScale: 0.3, className: 'l7oqt3vxfn-editor_css' },
  titleWrapper: {
    className: 'title-wrapper l7optvf4blc-editor_css',
    children: [
      {
        name: 'content',
        className:
          'title-content content4-title-content l7kyrnu5d3r-editor_css',
        children: (
          <span>
            <span>
              <p>山西瑞露君洋酒业有限公司</p>
            </span>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video l7kyfttedr6-editor_css',
    children: {
      video:
        'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruilujunyang.m4v',
      image: '',
    },
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7om0oqhooh-editor_css',
    playScale: 0.3,
    always: true,
    appear: true,
  },
  titleWrapper: {
    className: 'title-wrapper l7om4o6vrql-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>53°瑞露千秋</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1 l7ooet00i88-editor_css',
      },
      {
        name: 'content~l7oq4vpms',
        className: 'l7oqcl9n2it-editor_css',
        children: (
          <span>
            <p>《千秋节赐群臣镜》</p>
            <p>唐 李隆基</p>
            <p>瑞露垂花绶，寒冰澈宝轮。</p>
            <p>对兹台上月，聊以庆佳辰。</p>
          </span>
        ),
      },
      {
        name: 'image~l7onavm41u',
        className: 'l7onawccg2-editor_css',
        children:
          'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/qianqiu1.jpeg',
      },
      {
        name: 'content~l7oq4siiq7',
        className: 'l7oqbdbb1cq-editor_css',
        children: (
          <span>
            <p>· “53°”醇瑞露 以铜为镜正千秋 ·</p>
          </span>
        ),
      },
      {
        name: 'content~l7oq4t86ok',
        className: 'l7oqbra0gdh-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            唐·开元十七年，八月初五“千秋节”，圣人于花萼楼宴请百官，<span
                            >
                              赠以千秋镜，喻以铜为镜，清白做人，清廉为官。
                            </span>
                          </p>
                          <p>
                            53°清香瑞露，携清雅君子之酒，承袭古法匠艺，<span>
                              引杏花村甘露神泉，112
                              天超长发酵，带来天性“醇”至享受。
                            </span>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7oq4tsr2qq',
        className: 'l7oqbwwy43r-editor_css',
        children: (
          <span>
            <p>· 30 岁月 “陳” ·</p>
          </span>
        ),
      },
      {
        name: 'content~l7oq4u9qwmt',
        className: 'l7oqce1fu16-editor_css',
        children: (
          <span>
            <span>
              <p>只用 15 年贮存基酒，匠心勾调 30 年以上窖藏珍露。</p>
              <p>杯酒人生泰然处之。</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7oq4upig2e',
        className: 'l7oqci4lj-editor_css',
        children: (
          <span>
            <p>· 是之谓“人间有味是清欢” ·</p>
          </span>
        ),
      },
      {
        name: 'content~l7oq4v6ce7b',
        className: 'l7oqcl9n2it-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      闻鉴有新鲜薄荷、菠萝、核桃、杏花与茉莉花的爽净清香，<span>
                        予以无以言表的愉悦印象。
                      </span>
                    </p>
                    <p>
                      <span>
                        入口柔和，充斥番石榴与银杏的绵柔恬净，香茗馥郁层次丰厚，味聚而不散。
                      </span>
                      <span>
                        余味有粱米的余香与杏苷的纯厚，香叶流转间品味悠扬古韵。
                      </span>
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ooir9alqs-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper l7oomgk71gj-editor_css',
    children: [
      {
        name: 'image',
        children:
          'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/qianqiu2.jpeg',
        className: 'title-image l7ooiz0izy-editor_css',
      },
      {
        name: 'content~l7oqf6u6obi',
        className: 'l7oojezg8ki-editor_css',
        children: (
          <span>
            <p>· “手作”匠心 堪舆千秋·</p>
          </span>
        ),
      },
      {
        name: 'content~l7oqf7yiz9f',
        className: 'l7oqfgqg6b6-editor_css',
        children: (
          <span>
            <span>
              <span>
                <p>
                  瓮·酵：固态地缸，分离发酵，拒绝杂菌与其他干扰，赋予酒曲纯净的生命力。
                </p>
                <p>
                  清·蒸：古法二次清，以甄蒸取，其清如水，味极清冽，盖瑞露也。
                </p>
                <p>
                  器·净：“清”为魂，“净”为本，千年传承的椒水浸缸杀菌与密闭发酵相辅而成瑞露隽永清香。
                </p>
                <p>
                  瓷·藏：独特的陶瓷酒瓶设计，使佳酿可以通过细小气孔持续呼吸发酵，酒随器酵，愈久弥香。
                </p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7oqf8kqnns',
        className: 'l7oqfn38nkm-editor_css',
        children: (
          <span>
            <p>· 一片冰心在玉壶 ·</p>
          </span>
        ),
      },
      {
        name: 'content~l7oqf93npp6',
        className: 'l7oqftezgfo-editor_css',
        children: (
          <span>
            <p>李白《留客中行》诗云“玉碗盛来琥珀光”。</p>
            <p>品杏花玉露，当以玉杯盛之，以玉增其色。</p>
            <p>
              瑞露千秋拟选开元玉壶春瓶，景德镇烧制，开片温润，却道翩翩君子，一片冰心在玉壶。
            </p>
          </span>
        ),
      },
      {
        name: 'content~l7oqf9ohdu',
        className: 'l7oqg104i0b-editor_css',
        children: (
          <span>
            <p>· 诗辉镜耀 ·</p>
          </span>
        ),
      },
      {
        name: 'content~l7oqfa7gbx',
        className: 'l7oqg8gn93t-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>樋口隆康先生言，唐诗与鉴，彰相辉映。</p>
                          <p>瑞露择唐镜凤凰图腾，更寓吉祥。</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7oqmf5cx',
        className: 'l7oqmh7qr7l-editor_css',
        children: (
          <span>
            <p>· 酒做人生 君子之酒 ·</p>
          </span>
        ),
      },
      {
        name: 'content~l7oqmkhgcv8',
        className: '',
        children: (
          <span>
            <p>
              清纯温厚不张扬，形神兼备显低调，糅千年古韵，吸时代豪气，贯清香醇正于当今盛世。
            </p>
            <p>品开元盛韵，饮瑞露千秋。</p>
          </span>
        ),
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7oou3ijo5-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper l7op1zqj24l-editor_css',
    children: [
      {
        name: 'image',
        children:
          'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/qianqiu3.jpeg',
        className: 'title-image l7ooud2jqd8-editor_css',
      },
      {
        name: 'content~l7oqk4g1es',
        className: 'l7op2z2rpsn-editor_css',
        children: (
          <span>
            <span>
              <p>· 产品参数 ·</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7oqk713d4q',
        className: '',
        children: (
          <span>
            <p>
              <span>品牌：瑞露千秋</span>
              <br />
            </p>
            <p>酒型：清香型白酒</p>
            <p>规格：500ML/53 度</p>
            <p>不适用人群：未满 18 岁请勿饮酒</p>
            <p>储存方法：请置于避光、干燥、通风处保存</p>
            <p>原料：水、高粱、大曲（大麦、豌豆）</p>
          </span>
        ),
      },
      {
        name: 'title',
        children: (
          <span>
            <p>建议零售价·1288/瓶</p>
          </span>
        ),
        className: 'title-h1 l7op2s0msla-editor_css',
      },
    ],
  },
};
export const Teams12DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper l7oqx4rtc9p-editor_css',
  },
  page: { className: 'home-page teams1 l7op4inssm-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper l7opozl91ll-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
        className: 'l7opoqb0iy6-editor_css',
      },
    ],
  },
  block: {
    className: 'block-wrapper l7oqqz3o73-editor_css',
    children: [
      {
        name: 'block0',
        className: 'block l7oruayoj8m-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu1.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title l7op6mi1wg6-editor_css',
              children: (
                <span>
                  <p>澈水清流 甘露杏花泉</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job l7op85weotj-editor_css',
              children: (
                <span>
                  <p>
                    “神井宝泉”清澈透明、清冽干爽，含有丰富微量元素，成就了瑞露清骨酒源。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block l7oru0l897-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu2.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title l7op6sng759-editor_css',
              children: (
                <span>
                  <p>伏曲夏制 三部酒骨杏花香</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job l7op8b5kbi6-editor_css',
              children: (
                <span>
                  <p>
                    274
                    种酿造清香型白酒的微生物，利于优质酒分子形成。“闭环生态”孕育的酒曲，成就瑞露千秋品质巅峰。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block l7orts4livn-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu3.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title l7op70euv6m-editor_css',
              children: (
                <span>
                  <p>佳秫珍麦 纯粮馏酿</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job l7op7e4yfzk-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      从山西沁县到甘肃山丹军马场，汾属卵粒粳高粱烙印在瑞露酒原粮基地的广袤身影中。
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content133DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7or62lg05-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper l7ptu7gzbii-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>42°瑞露君蘭</p>
          </span>
        ),
        className: 'title-h1 l7or6dke1pe-editor_css',
      },
      {
        name: 'content~l7or6jitdo',
        className: 'l7orhbvyxqp-editor_css',
        children: (
          <span>
            <p>·“45°”清雅兰 对酒当歌与君欢·</p>
          </span>
        ),
      },
      {
        name: 'content~l7or6kc3xrb',
        className: '',
        children: (
          <span>
            <p>“清风摇翠环，凉露滴苍玉。”</p>
            <p>
              45°清香君兰，习君子之道，引兰香幽韵，得自然之助，以古法融汇，成就典雅新风尚。
            </p>
            <p>君子之交，当饮君兰。</p>
          </span>
        ),
      },
      {
        name: 'content~l7or6kwcfrn',
        className: 'l7orhh174e7-editor_css',
        children: (
          <span>
            <p>· 20 岁月品质 点滴琼浆幽兰香 ·</p>
          </span>
        ),
      },
      {
        name: 'content~l7or6llwy1',
        className: '',
        children: (
          <span>
            <p>闻鉴有新鲜葡萄与兰花的淡雅芳香，沁人心脾。</p>
            <p>入口柔美，充斥杏花与樱桃的醇甜细腻，味爽一线喉。</p>
            <p>余味有兰芷的清香与银杏的甘甜，绵延悠长。</p>
          </span>
        ),
      },
      {
        name: 'content~l7or74d6e3j',
        className: 'l7ori2i6ze6-editor_css',
        children: (
          <span>
            <p>·以酒会友，义结金兰契·</p>
          </span>
        ),
      },
      {
        name: 'content~l7or779w32r',
        className: '',
        children: (
          <span>
            <span>
              <p>
                “人生得意须尽欢，莫使金樽空对月”，以清正君子之<span>
                  酒，会天下贤良，致友谊万古长青。
                </span>
              </p>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7orkkscv2',
        className: 'l7orkv5ipwg-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      · 产品参数 ·<br />
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7orkmw9m4e',
        className: '',
        children: (
          <span>
            <p>品牌：瑞露千秋</p>
            <p>酒型：清香型白酒</p>
            <p>规格：500ML/45 度</p>
            <p>不适用人群：未满 18 岁请勿饮酒</p>
            <p>储存方法：请置于避光、干燥、通风处保存</p>
            <p>原料：水、高粱、大曲（大麦、豌豆）</p>
          </span>
        ),
      },
      {
        name: 'title~l7or7djcb2',
        className: 'l7or7ig6o56-editor_css',
        children: (
          <span>
            <p>建议零售价·488/瓶</p>
          </span>
        ),
      },
    ],
  },
};
export const Teams13DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper l7oqwz4wtn-editor_css',
  },
  page: { className: 'home-page teams1 l7oqwj4o1og-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper l7oqwl22th-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
        className: 'l7oqw6kilua-editor_css',
      },
    ],
  },
  block: {
    className: 'block-wrapper l7oqy7x5vsg-editor_css',
    children: [
      {
        name: 'block0',
        className: 'block l7oqxid56hu-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruilujunlan1.jpg',
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block l7oqyromixj-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruilujunlan2.jpg',
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block l7oqygnhgna-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruilujunlan3.jpg',
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content134DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7pt2882cl-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper l7ptug6w4hp-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>代理商服务业务</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1 l7pt3hetyfo-editor_css',
      },
      {
        name: 'content~l7pt74o00n',
        className: 'l7pt95slngt-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>·酒类口味专家·</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7pt76dllji',
        className: '',
        children: (
          <span>
            <p>新时代酒类口味研究专家</p>
            <p>借旧时风骨，研时代之酒。</p>
            <p>互动型Lab创新调制，融汇世界时空技艺。</p>
          </span>
        ),
      },
      {
        name: 'content~l7pt58cb9wl',
        className: 'l7pt9q0zm8i-editor_css',
        children: (
          <span>
            <span>
              <p>·专业营销团队·</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7pt59l8vvm',
        className: '',
        children: (
          <span>
            <p>一站式精细化营销服务，因地制宜开拓广袤商机</p>
            <p>商业广告定期投放，减少代理商运营成本。</p>
          </span>
        ),
      },
      {
        name: 'content~l7pt5a4of7',
        className: 'l7pt9to3r8k-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    <u>·全球贸易网络·</u>
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l7pt5b0mz9f',
        className: '',
        children: (
          <span>
            <p>全球代理进出口覆盖增值服务，汇通天下酒类市场良机。</p>
          </span>
        ),
      },
    ],
  },
};
export const Footer11DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer1-wrapper l7l01itst6-editor_css',
  },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruilulogo.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block l7kzuoo3j8m-editor_css',
        title: {
          children: (
            <span>
              <span>
                <p>联系我们</p>
              </span>
            </span>
          ),
          className: 'l7kzv31qnip-editor_css',
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>山西瑞露君洋酒业有限公司</p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'l7kzuthdtt-editor_css',
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            地址：山西省 太原市 小店区
                            龙城北街龙城壹号
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'l7kzuweiuji-editor_css',
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>联系电话：13962989548</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'l7kzuz5iz8s-editor_css',
            },
            {
              name: 'link3',
              href: '#',
              children: (
                <span>
                  <p>联系邮箱：manager@phoenixdew.cn</p>
                </span>
              ),
              className: 'l7kzv14b6cr-editor_css',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'l7kzuj6a0a-editor_css copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>©</span>2022 Phoenix Dew All
                                        Rights Reserved
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <div>
                                <span>
                                  <span>
                                    <p>晋ICP备2022007821号-1</p>
                                  </span>
                                </span>
                              </div>
                            </a>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
